<script setup lang='ts'>
import type { IPublicDirectStore } from '@/stores/appdata-public-direct';
import { useAppDataStore } from '@/stores/appdata';

const route = useRoute();
const store = useAppDataStore() as IPublicDirectStore;
const user = useUser();

const parseSlug = async () => {
    const slugParts = route.params.slug || [];

    const areaId = Number(slugParts[1]) || 0;
    const categoryId = Number(slugParts[3]) || 0;

    if (!areaId) {
        if (store.areas.length === 0) {
            await store.loadAreas();
        }

        await navigateTo(`/${store.areas[0].slug}/${store.areas[0].id}`, { replace: true });
        parseSlug();
        return;
    }

    if (store.areaId !== areaId) {
        store.setAreaName(slugParts[0] || '');
        store.setAreaId(Number(slugParts[1]) || 0);

        store.categories = [];
    }

    if (!categoryId) {
        // reset the category id and name cos we're dealing with a new area
        store.setCategoryId(null);
        store.setCategoryName('');

        await store.loadCategories();
        await navigateTo(`/${slugParts[0]}/${slugParts[1]}/${store.categories[0].slug}/${store.categories[0].id}`, { replace: true });
        parseSlug();

        return;
    } else if (store.categories.length === 0) {
        await store.loadCategories();
    }

    if (store.categoryId !== categoryId) {
        store.setCategoryName(slugParts[2] || '');
        store.setCategoryId(Number(slugParts[3]) || 0);
    }
};

onMounted(async () => {
    await store.loadAreas();
    parseSlug();
});

watch(() => route.params.slug, parseSlug, { deep: true });

</script>

<template>
    <div class="layout-main-wrapper font-primary font-normal flex flex-col flex-1" :class="[user && 'pb-20 md:pb-0']">
        <AppTopbar class="" />
        <div class="layout-main-container w-full pr-0 pl-0">
            <div class="layout-main h-full">
                <slot />
            </div>
        </div>

        <AppFooter class="hidden md:block" />
    </div>
</template>

<style lang="scss" scoped>

</style>
